// generic
import global from './global_es.json'
import datas from './datas_es.json'
import errors from './errors_es.json'
import menu from './menu_es.json'
import offers from './offers_es.json'
import inputs from './inputs_es.json'
// pages auth
import login from './login_es.json'
import register from './register_es.json'
import reset from './reset_es.json'
import forgot from './forgot_es.json'
import welcome from './welcome_es.json'
import onboarding from './onboarding_es.json'
import agent from './agent_es.json'
// pages app
import dashboard from './dashboard_es.json'
import interventions from './interventions_es.json'
import intervention from './intervention_es.json'
import settings from './settings_es.json'
import users from './users_es.json'
import customers from './customers_es.json'
import profile from './profile_es.json'

export default {
  // generic
  global,
  datas,
  errors,
  offers,
  menu,
  inputs,
  // pages auth
  login,
  register,
  reset,
  welcome,
  forgot,
  onboarding,
  agent,
  // pages app
  dashboard,
  interventions,
  intervention,
  settings,
  users,
  customers,
  profile
}
