import { Fragment, createContext, useState, useContext, useEffect, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Transition } from '@headlessui/react'
import { CheckCircleIcon, ExclamationTriangleIcon, ShieldExclamationIcon, InformationCircleIcon } from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/20/solid'

type NotificationsProviderProps = {
children: ReactNode
}

type Status = 'SUCCESS' | 'WARNING' | 'ERROR' | 'INFO'
//  type Color = 'green' | 'orange' | 'red' | 'blue'

type OpenProps = {
  title: string
  status?: Status
  description?: string
  delay?: number
  canBeClose?: boolean
  undo?: () => void
}

type IconProps = {
  status?: Status
}

type ProgressBarProps = {
  progress: number
  status?: Status
}

type OpenFunc = ({ title, status, description, delay, canBeClose, undo }: OpenProps) => void

type CloseFunc = () => void

type InitialValues = {
  openNotification: OpenFunc
  closeNotification: CloseFunc
}

const initialValues: InitialValues = {
  openNotification: () => {},
  closeNotification: () => {}
}

const NotificationsContext = createContext(initialValues)

const Icon = ({ status = 'SUCCESS' }: IconProps) => { // Dirty but the useMemo solution was not correctly updating the color
  return status === 'INFO'
    ? <InformationCircleIcon className='text-blue-400 h-6 w-6' />
    : status === 'WARNING'
      ? <ExclamationTriangleIcon className='text-orange-400 h-6 w-6' />
      : status === 'ERROR'
        ? <ShieldExclamationIcon className='text-red-400 h-6 w-6' />
        : <CheckCircleIcon className='text-green-400 h-6 w-6' />
}

const ProgressBar = ({ status = 'SUCCESS', progress }: ProgressBarProps) => { // Dirty but the useMemo solution was not correctly updating the color
  return status === 'INFO'
    ? <div className="w-full bg-gray-200 h-2.5 dark:bg-gray-700">
        <div className='bg-blue-600 h-2.5' style={{ width: `${progress}%` }}></div>
      </div>
    : status === 'WARNING'
      ? <div className="w-full bg-gray-200 h-2.5 dark:bg-gray-700">
          <div className='bg-orange-600 h-2.5' style={{ width: `${progress}%` }}></div>
        </div>
      : status === 'ERROR'
        ? <div className="w-full bg-gray-200 h-2.5 dark:bg-gray-700">
            <div className='bg-red-600 h-2.5' style={{ width: `${progress}%` }}></div>
          </div>
        : <div className="w-full bg-gray-200 h-2.5 dark:bg-gray-700">
            <div className='bg-green-600 h-2.5' style={{ width: `${progress}%` }}></div>
          </div>
}

const NotificationsProvider = ({ children }: NotificationsProviderProps) => {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [params, setParams] = useState<OpenProps>({
    title: 'Notifications',
    status: 'SUCCESS',
    description: '',
    delay: 3000,
    canBeClose: true,
    undo: undefined
  })

  const [delayDown, setDelayDown] = useState<number>(0)

  useEffect(() => {
    if (delayDown <= 0) {
      setIsOpen(false)
      return
    }
    const timeout = setTimeout(() => {
      setDelayDown(prev => prev - 10)
    }, 10)

    return () => clearTimeout(timeout)
  }, [delayDown])

  const openNotification: OpenFunc = ({ title, status = 'SUCCESS', description, delay = 3000, canBeClose = true, undo = undefined }) => {
    setParams({
      title,
      status,
      description,
      delay,
      canBeClose,
      undo
    })
    setDelayDown(delay)
    setIsOpen(true)
  }

  const closeNotification: CloseFunc = () => {
    setDelayDown(0)
    // setIsOpen(false)
  }

  return (
    <NotificationsContext.Provider value={{
      openNotification,
      closeNotification
    }}>
      {children}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={isOpen}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <Icon status={params.status} />
                  </div>
                  <div className="ml-3 w-0 flex flex-1 pt-0.5 justify-between items-center">
                    <div>
                      <p className="text-sm font-medium text-gray-900">{params.title}</p>
                      {params.description
                        ? <p className="mt-1 text-sm text-gray-500">{params.description}</p>
                        : null
                      }
                    </div>
                    {params.undo
                      ? <button
                        type="button"
                        className="ml-3 h-fit flex-shrink-0 rounded-md bg-white text-sm font-medium text-primary-dark hover:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                      >
                        {t('global:notifications.undo')}
                      </button>
                      : null
                    }
                  </div>
                  {params.canBeClose
                    ? <div className="ml-4 flex flex-shrink-0">
                        <button
                          type="button"
                          className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:none"
                          onClick={closeNotification}
                        >
                          <span className="sr-only">{t('global:notifications.close')}</span>
                          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                    : null
                  }
                </div>
              </div>
              <ProgressBar status={params.status} progress={100 - (delayDown * 100 / params.delay!)} />
            </div>
          </Transition>
        </div>
      </div>
    </NotificationsContext.Provider>
  )
}

const useNotifications = () => {
  return useContext(NotificationsContext)
}

export { NotificationsProvider, useNotifications }
